import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "iconoir-react";
import * as React from "react";
import { cn } from "utils/utils.ts";

export type CheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "type"
> & {
  type?: string;
};

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "peer/checkbox enabled:hover:bg-green-0 ring-offset-background focus-visible:ring-ring peer h-5 w-5 shrink-0 rounded-sm border border-gray-300 transition-colors duration-150 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 enabled:hover:border-gray-500 disabled:cursor-not-allowed disabled:opacity-70 data-[state=checked]:border-green-600 data-[state=checked]:bg-green-600 data-[state=checked]:text-white enabled:hover:data-[state=checked]:border-green-700 enabled:hover:data-[state=checked]:bg-green-700",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="size-5" strokeWidth={3} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
