import * as React from "react";
import { cn } from "utils/utils.ts";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "active:text-secondary focus-visible:border-teal flex h-10 w-full rounded-[8px] border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 ring-0 ring-offset-0 transition-all duration-150 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 read-only:bg-gray-100 read-only:text-gray-300 focus:outline-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 active:border-green-500 enabled:hover:border-green-400 enabled:active:border-2 enabled:active:outline-0 enabled:active:ring-0 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300",
          className,
          // disabled:opacity-75 block bg-light-iron placeholder:text-dark-iron placeholder:text-xs border-2 border-iron w-full appearance-none rounded-md focus:border-dark-iron focus:outline-none text-midnightblue focus:outline-teal focus:ring-0 text-sm outline-0 ring-0 focus:outline-0  active:border-teal active:outline-0 active:outline-teal active:ring-0
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
